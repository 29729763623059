<template>
  <div>
    <el-card>
      <div class="header">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <el-form-item label="账号">
            <el-input
              v-model="queryForm.username"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="昵称">
            <el-input
              v-model="queryForm.nickname"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="queryForm.phone" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getListData">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div
        class="tool"
        style="background: #fafafa;display: flex;padding: 10px;border: 1px solid #ebeef5;border-bottom: 0;"
      >
        <div>
          <el-button
            size="small"
            type="primary"
            @click="addClick"
            v-permission="['sys:sysUser:add']"
            >添加
          </el-button>
          <el-button
            size="small"
            type="danger"
            @click="deleteBatchClick"
            v-permission="['sys:sysUser:delete']"
            >删除</el-button
          >
        </div>
        <div style="flex: 1;text-align: right;">
          <el-button size="small" type="primary" @click="getListData" plain
            >刷新列表
          </el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          border
          v-loading="loading"
          :header-cell-style="{
            background: '#fafafa',
            color: '#333333',
            padding: '4px 0'
          }"
          :cell-style="{ padding: '4px 0' }"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @sort-change="handleSortChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column property="username" label="账号" />
          <el-table-column property="nickname" label="昵称" />
          <!--          <el-table-column property="sex" label="性别">
            <template #default="scope">
              <template v-if="scope.row.status == 0">未知</template>
              <template v-if="scope.row.status == 1">男</template>
              <template v-if="scope.row.status == 2">女</template>
            </template>
          </el-table-column>-->
          <el-table-column property="phone" label="手机号" />
          <el-table-column property="roles" label="角色">
            <template #default="scope">
              <template v-for="(role, index) in scope.row.roles" :key="index">
                <el-tag style="margin-right: 5px;">{{ role.roleName }}</el-tag>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            property="createTime"
            sortable="custom"
            label="创建时间"
          />
          <el-table-column property="status" label="状态">
            <template #default="scope">
              <el-tag type="info" v-if="scope.row.status == 0">停用</el-tag>
              <el-tag type="success" v-if="scope.row.status == 1">正常</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="updateClick(scope.row)"
                :disabled="scope.row.userId === 1"
                v-permission="['sys:sysUser:update']"
                >修改
              </el-button>
              <el-button
                type="text"
                size="small"
                :disabled="scope.row.userId === 1"
                @click="resetUserPasswordClick(scope.row)"
                v-permission="['sys:sysUser:update']"
                >重置密码
              </el-button>
              <el-button
                type="text"
                size="small"
                :style="{
                  color: scope.row.userId === 1 ? '#c0c4cc' : '#F56C6C'
                }"
                :disabled="scope.row.userId === 1"
                @click="deleteClick(scope.row)"
                v-permission="['sys:sysUser:delete']"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center;padding-top: 20px;">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryForm.limit"
          :current-page="queryForm.page"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>

    <add-user
      v-if="addUserDialogVisible"
      v-model="addUserDialogVisible"
      @close="getListData"
    >
    </add-user>

    <edit-user
      v-if="editUserDialogVisible"
      v-model="editUserDialogVisible"
      :userId="selectItemId"
      @close="getListData"
    >
    </edit-user>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  getSysUserPage,
  deleteSysUser,
  deleteBatchSysUser,
  resetPassword
} from '@/api/sys-user'
import addUser from './components/AddUser'
import editUser from './components/EditUser'
// 查询
const queryForm = ref({
  page: 1,
  limit: 10,
  sort: 'createTime',
  order: 'desc',
  username: '',
  phone: '',
  nickname: ''
})

/**
 * 选择要操作的列表id
 */
const selectItemId = ref(0)

/**
 * 表格数据相关
 */

const loading = ref(false)
const tableData = ref([])
const total = ref(0)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await getSysUserPage(queryForm.value)
  tableData.value = result.list
  total.value = result.total
  loading.value = false
}
getListData()

/**
 * 根据创建时间排序
 */
const handleSortChange = val => {
  queryForm.value.sort = val.prop
  console.log(val.order)
  if (val.order === 'ascending') {
    queryForm.value.order = 'asc'
  } else {
    queryForm.value.order = 'desc'
  }
  getListData()
}

/**
 * 选中的列表id数组
 */
const selectItemIds = ref([])
const handleSelectionChange = val => {
  selectItemIds.value = []
  val.forEach(row => {
    selectItemIds.value.push(row.userId)
  })
}

/**
 * 分页数量改变事件
 */
const handleSizeChange = val => {
  queryForm.value.limit = val
  getListData()
}
const handleCurrentChange = val => {
  queryForm.value.page = val
  getListData()
}

/**
 * 添加系统用户点击事件
 */
const addUserDialogVisible = ref(false)
const addClick = () => {
  addUserDialogVisible.value = true
}

/**
 * 更新用户点击事件
 * @param row
 */
const editUserDialogVisible = ref(false)
const updateClick = row => {
  selectItemId.value = row.userId
  editUserDialogVisible.value = true
}
/**
 * 重置密码点击事件
 * @param row
 */
const resetUserPasswordClick = row => {
  ElMessageBox.prompt(
    '请输入要设置的新密码',
    '重置 ' + row.nickname + ' 密码',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消'
    }
  )
    .then(async ({ value }) => {
      await resetPassword({ userId: row.userId, password: value })
      ElMessage({
        type: 'success',
        message: '重置密码成功'
      })
      getListData()
    })
    .catch(() => {})
}
/**
 * 删除用户点击事件
 * @param row
 */
const deleteClick = row => {
  ElMessageBox.confirm(
    '确认删除 ' + row.nickname + ' (' + row.username + ')账号吗？',
    '删除',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  )
    .then(async () => {
      await deleteSysUser(row.userId)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      getListData()
    })
    .catch(() => {})
}

/**
 * 批量删除用户点击事件
 * @param data
 * @returns {Promise<void>}
 */
const deleteBatchClick = () => {
  ElMessageBox.confirm('确认批量删除选中账号吗？', '批量删除', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      await deleteBatchSysUser(selectItemIds.value)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      getListData()
    })
    .catch(() => {})
}
</script>

<style lang="scss" scoped></style>
